'use strict'
// TODO: Spostare su servizio generale in cloud
require('dotenv').config();
import PouchDB from 'pouchdb';
PouchDB.plugin(require('pouchdb-find')); // npm install --save pouchdb-find
var pouchCollate = require('pouchdb-collate')

const service = process.env.VUE_APP_service_licenze || ''

const getProdottiByIds = async (base, ids, limit) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'prodotti' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return

    let opz = {include_docs: true, keys: ids}
    if (limit > 0) {
      opz.limit = limit
    }
    let result = await db.allDocs(opz);
    result.rows = result.rows.filter(x => !x.error && !x.value.deleted)
    await db.close()
    const elenco = result.rows.map(x => x.doc).filter(x => x && !x._id.startsWith('_'));
    return elenco
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}


const getProdotto = async (base, id) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'prodotti' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    const result = await db.get(id);
    await db.close()
    return result
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

const setProdotto = async (base, prodotto) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'prodotti' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    prodotto.ricerca = creaCampoRicerca(prodotto).toLowerCase()
    prodotto.ricercaTC = creaCampoRicercaTC(prodotto).toLowerCase()
    prodotto.ricercaBarcode = creaCampoRicercaBarcode(prodotto)

    prodotto.editInfo = prodotto.editInfo || {}
    prodotto.editInfo.dataModifica = new Date()

    await db.put(prodotto);
    const newProd = await db.get(prodotto._id)
     
    await db.close()

    return newProd
  }
  catch (err) {
    if (err.name === 'conflict') {
      // winston.error(`CONFLICT -> prod_save :${prodotto._id} - ${prodotto._rev}`);
      let locProd = db.get(prodotto._id)
      delete prodotto._rev
      locProd = _.merge(locProd, prodotto)
      await db.put(locProd);
    } else {
      // winston.error(`prod_save (${err.name}): ${prodotto._id} - ${prodotto._rev}`);
    }
    await db.close()
    return err;
  }
}

async function aggiornaPrezzo(base, tipoPrezzo, prodottoId, chiave, valore)  {
  // tipo = listino / cliente/ tipoCliente/ acquisto
  // chiave = (se listino) --> codiceListino, (se cliente) --> codiceCliente, (se TipoCliente) --> tipoCliente
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'prodotti' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;

  var result = {}
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    const prodotto = await db.get(prodottoId);
    if (prodotto && !prodotto.error) {
      switch (tipoPrezzo) {
        case 'acquisto':
          _.set(prodotto, 'magazzino.prezzoAcquisto', valore)
          break
        case 'listino':
          _.set(prodotto, `prezzo.${chiave}.base`, valore)
          break
        case 'cliente':
          _.set(prodotto, `prezzo._L_${chiave}.base`, valore)
          break
        case 'tipologia':
          _.set(prodotto, `prezzo._LT_${chiave}.base`, valore)
          break
        default:
          break
      }
      prodotto.editInfo = prodotto.editInfo || {}
      prodotto.editInfo.dataModifica = new Date();
      result = await db.put(prodotto);  
    }
  } catch (error) {
    return null
  } finally {
    await db.close()
  }
  return result
}

const getFilteredProducts = async (base, filtri, opzioni) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'prodotti' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  const urlAuth = { urlDB, username, password }
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    let ids = []
    let filtroApplicato = false
    var pp
    if (filtri && filtri.barcode) {
      let pr = await prod_get_from_barcode_index (urlAuth, filtri.barcode)
      if (!pr) {
        pr = await prod_get_from_barcode_array (urlAuth, filtri.barcode)
      }
      if (pr) {
        ids = filtroApplicato ? _.intersection(ids, [pr._id]) : [pr._id]
        filtroApplicato = true
      } else {
        return { total_rows: 0 }
      }
    }

    if (filtri && (filtri.codice || filtri.descrizione)) {
      pp = await prod_search_by_code_description(urlAuth, { codice: filtri.codice, descrizione: filtri.descrizione }, (opzioni && opzioni.itemsPerPage) || 1000)
      if (pp.length > 0) {
        ids = filtroApplicato ? _.intersection(ids, pp.map(x => x._id)) : pp.map(x => x._id)
        filtroApplicato = true
      } else {
        return { total_rows: 0 }
      }
    }
 
    const opz = {
      itemsPerPage: opzioni && opzioni.itemsPerPage || 20,
      page: opzioni && opzioni.page || 1
    }
    const dbOptions = {
      include_docs: true,
      limit: opz.itemsPerPage,
      skip: (opz.page - 1) * opz.itemsPerPage
    }
    if (ids.length > 0) {
      dbOptions.keys = ids
    } else {
      if (filtroApplicato || (filtri && (filtri.codice || filtri.barcode))) {
        await db.close()
        return {
          rows: [],
          total_rows: 0
        }
      }
    }
    let prodotti = await db.allDocs(dbOptions);
    prodotti.total_rows = ids.length || prodotti.total_rows
    await db.close()
    return prodotti
  }
  catch (err) {
    console.log(err)
    await db.close()
    return err;
  }
}

/* const getFilteredProducts2 = async (base, filtri, opzioni) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'prodotti' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  const urlAuth = { urlDB, username, password }
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    let ids = []
    let filtroApplicato = false
    var pp
    if (filtri && filtri.barcode) {
      let pr = await prod_get_from_barcode_index (urlAuth, filtri.barcode)
      if (pr) {
        ids = filtroApplicato ? _.intersection(ids, [pr._id]) : [pr._id]
        filtroApplicato = true
      } else {
        return { total_rows: 0 }
      }
    }

    if (filtri && (filtri.codice || filtri.descrizione)) {
      pp = await prod_search_by_code_description(urlAuth, { codice: filtri.codice, descrizione: filtri.descrizione }, (opzioni && opzioni.itemsPerPage) || 1000)
      if (pp.length > 0) {
        ids = filtroApplicato ? _.intersection(ids, pp.map(x => x._id)) : pp.map(x => x._id)
        filtroApplicato = true
      } else {
        return { total_rows: 0 }
      }
    }
 
    const opz = {
      itemsPerPage: opzioni && opzioni.itemsPerPage || 20,
      page: opzioni && opzioni.page || 1
    }
    const dbOptions = {
      include_docs: true
     // limit: opz.itemsPerPage,
     //  skip: (opz.page - 1) * opz.itemsPerPage
    }
    if (ids.length > 0) {
      dbOptions.keys = ids
    } else {
      if (filtroApplicato || (filtri && (filtri.codice || filtri.barcode))) {
        await db.close()
        return {
          rows: [],
          total_rows: 0
        }
      }
    }
    const elenco = await db.allDocs(dbOptions);
    let prodotti = elenco.rows.map(x => x.doc);
    debugger
    if (filtri && filtri.descrizione) {
      prodotti = prodotti.filter(x => x.descrizione.includes(filtri.descrizione))
    }
    if (filtri && filtri.marchio && filtri.marchio.length > 0) {
      prodotti = prodotti.filter(x => x.marchio === filtri.marchio)
    }    
    if (filtri && filtri.categoria1 && filtri.categoria1.length > 0) {
      prodotti = prodotti.filter(x => x.statistici.categoria1.includes(filtri.categoria1))
    }
    if (filtri && filtri.categoria2 && filtri.categoria2.length > 0) {
      prodotti = prodotti.filter(x => x.statistici.categoria2.includes(filtri.categoria2))
    }    
    await db.close()
    const limit = opz.itemsPerPage
    const skip = (opz.page - 1) * opz.itemsPerPage
    return { rows: _.take(_.drop(prodotti, skip), limit), total_rows: prodotti.length }
  }
  catch (err) {
    console.log(err)
    await db.close()
    return err;
  }
} */

const prod_get_from_barcode_index = async (urlAuth, barcode) => {
  try {
    const data = {
      selector: {
        'ricercaBarcode': {
          '$eq': barcode
        }
      },
      use_index: 'barcode-ricerca-index',
      fields: ['_id', 'codice', 'tipoBene', 'tipoItem', 'descrizione', 'varianti', 'ricercaTC']
    }
    const basicAuth = 'Basic ' + Buffer.from(`${urlAuth.username}:${urlAuth.password}`, 'utf-8').toString('base64')
    const response = await fetch(`${urlAuth.urlDB}/_find`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth
      },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    if (result.docs.length) return result.docs[0]
    return null // verificare era {}
  }
  catch (err) {
    console.log('errore search', err)
    return err;
  }
}

const prod_get_from_barcode_array = async (urlAuth, barcode) => {
  try {
    const data = {
      selector: {
        barcode: {
          "$elemMatch": { "codice": barcode, "attivo": true }
/*            '$elemMatch': {
              codice: {
                 '$eq': barcode
              }
           } */
        }
     },
      use_index: 'barcode-index',
      fields: ['_id', 'codice', 'tipoBene', 'tipoItem', 'descrizione', 'varianti', 'ricercaTC']
    }
    const basicAuth = 'Basic ' + Buffer.from(`${urlAuth.username}:${urlAuth.password}`, 'utf-8').toString('base64')
    // const response = await fetch(`${urlAuth.urlDB}/_find`, {
    const response = await fetch(`${urlAuth.urlDB}/_find`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth
      },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    if (result.docs.length) return result.docs[0]
    return null // verificare era {}
  }
  catch (err) {
    console.log('errore search', err)
    return err;
  }
}

const prod_search_by_code_description = async (urlAuth, filtri, limit) => {
  try {
    let data = {
      selector: {
      },
      use_index: 'prodotti-index-v2'
    }
    if (filtri.codice) {
      data.selector.codice = { 
        '$gte': filtri.codice.toLowerCase(),
        '$lte': `${filtri.codice.toLowerCase()}z`
      }
    } else {
      data.selector.codice = { '$gte': null }
    }
    if (filtri.descrizione) {
      // data.selector.descrizione = { '$gte': filtri.descrizione.toLowerCase() }
      data.selector.ricerca =  {"$regex": "\w*(" + filtri.descrizione.toLowerCase() + ")\w*"}
    }

    const basicAuth = 'Basic ' + Buffer.from(`${urlAuth.username}:${urlAuth.password}`, 'utf-8').toString('base64')
    const response = await fetch(`${urlAuth.urlDB}/_find`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth
      },
      body: JSON.stringify(data)
    })
    const result = await response.json()
 
    if (result.docs.length > 0) {
      return result.docs
    } else {
      return []
    }
  } catch (err) {
    console.log(err)
    return []
  }
}

const prod_list_by_filter = async (base, filtri, opzioni, condizioni) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'prodotti' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  const urlAuth = { urlDB, username, password }
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return

    let ids = []
    let filtroApplicato = false
    var pp
    if (filtri && filtri.barcode) {
      let pr = await prod_get_from_barcode_index (urlAuth, filtri.barcode)
      if (pr) {
        ids = filtroApplicato ? _.intersection(ids, [pr._id]) : [pr._id]
        filtroApplicato = true
      } else {
        // TODO: vado a cercare nella tabella dei barcode disattivati
/*         if (filtri.barcodeIncludeDisabled) {
          pr = await exports.prod_get_list_from_barcode (filtri.barcode)
          if (pr && pr.length > 0) {
            ids = filtroApplicato ? _.intersection(ids, pr.map(x => x._id)) : pr.map(x => x._id)
            filtroApplicato = true
          }
        } */
      }
    }

    if (filtri && filtri.codice) {
      pp = await prod_search_by_code_description(urlAuth, { codice: filtri.codice }, (opzioni && opzioni.itemsPerPage) || 1000)
      if (pp.length > 0) {
        ids = filtroApplicato ? _.intersection(ids, pp.map(x => x._id)) : pp.map(x => x._id)
      }
      filtroApplicato = filtroApplicato || (pp.length > 0)
    }

    if (filtri && filtri.descrizione) {
      pp = await prod_search_by_code_description(urlAuth, { descrizione: filtri.descrizione }, (opzioni && opzioni.itemsPerPage) || 1000)
      if (pp.length > 0) {
        ids = filtroApplicato ? _.intersection(ids, pp.map(x => x._id)) : pp.map(x => x._id)
      }
      filtroApplicato = filtroApplicato || (pp.length > 0)
    }
    if (filtri && filtri.marchio && filtri.marchio.length > 0) {
      pp = await prod_search_by_array(urlAuth, 'marchio', filtri.marchio)
      ids = filtroApplicato ? _.intersection(ids, pp.docs.map(x => x._id)) : pp.docs.map(x => x._id)
      filtroApplicato = true
    }

    if (filtri && filtri.variante1 && filtri.variante1.length > 0) {
      pp = await prod_search_by_array(urlAuth, 'varianti.variante1.codice', filtri.variante1)
      ids = filtroApplicato ? _.intersection(ids, pp.docs.map(x => x._id)) : pp.docs.map(x => x._id)
      filtroApplicato = true
    }

    if (filtri && filtri.variante2 && filtri.variante2.length > 0) {
      pp = await prod_search_by_array(urlAuth, 'varianti.variante2.codice', filtri.variante2)
      ids = filtroApplicato ? _.intersection(ids, pp.docs.map(x => x._id)) : pp.docs.map(x => x._id)
      filtroApplicato = true
    }
    if (filtri && filtri.categoria1 && filtri.categoria1.length > 0) {
      pp = await prod_search_by_array(urlAuth, 'statistici.categoria1.codice', filtri.categoria1)
      ids = filtroApplicato ? _.intersection(ids, pp.docs.map(x => x._id)) : pp.docs.map(x => x._id)
      filtroApplicato = true
    }
    if (filtri && filtri.categoria2 && filtri.categoria2.length > 0) {
      pp = await prod_search_by_array(urlAuth, 'statistici.categoria2.codice', filtri.categoria2)
      ids = filtroApplicato ? _.intersection(ids, pp.docs.map(x => x._id)) : pp.docs.map(x => x._id)
      filtroApplicato = true
    }

    if (filtri && filtri.soloGiacenzaPositiva) {
      pp = await prod_giacenza_positiva(urlAuth, filtri.magazzinoGiacenza)
      ids = filtroApplicato ? _.intersection(ids, pp.docs.map(x => x._id)) : pp.docs.map(x => x._id)
      filtroApplicato = true
    }      
    const opz = {
      itemsPerPage: opzioni && opzioni.itemsPerPage || 20,
      page: opzioni && opzioni.page || 1
    }
    const dbOptions = {
      include_docs: true,
      limit: opz.itemsPerPage,
      skip: (opz.page - 1) * opz.itemsPerPage
    }
    if (ids.length > 0) {
      dbOptions.keys = ids
    } else {
      if (filtroApplicato || (filtri && filtri.codice)) {
        return {
          rows: [],
          total_rows: 0
        }
      }
    }
    const prodotti = await db.allDocs(dbOptions);
    prodotti.rows = prodotti.rows.filter(x => !x.id.startsWith('_'))
    if (condizioni) {
      for (let d of prodotti.rows) {
        let p = d.doc
        // prezzo per il cliente specifico
        let scontiUlteriori = true
        const prezzoListino = _.get(p, `prezzo.${condizioni.listino}.base`, '')
        const prezzoCategoriaSconti = _.get(p, `prezzo.${condizioni.categoriaSconti}.base`, '')
        if (prezzoCategoriaSconti) {
          scontiUlteriori = _.get(p, `prezzo.${condizioni.categoriaSconti}.sconti`, true)
        }   

        const prezzoCliente = _.get(p, `prezzo.${condizioni.codice}.base`, '')
        if (prezzoCliente) {
          scontiUlteriori = _.get(p, `prezzo.${condizioni.codice}.sconti`, true)
        }
     
        let prezzo = prezzoCliente || prezzoCategoriaSconti || prezzoListino || (p.prezzo && p.prezzo.L1 && p.prezzo.L1.base)
        if (condizioni.sconti && scontiUlteriori) {
          prezzo = +(prezzo * (1 - (condizioni.sconti.sconto1 || 0) / 100)).toFixed(2)
          prezzo = +(prezzo * (1 - (condizioni.sconti.sconto2 || 0) / 100)).toFixed(2)
          prezzo = +(prezzo * (1 - (condizioni.sconti.sconto3 || 0) / 100)).toFixed(2)
          prezzo = +(prezzo * (1 - (condizioni.sconti.sconto4 || 0) / 100)).toFixed(2) 
        }
        let sconto = prezzoListino && prezzo ? +(prezzoListino - prezzo).toFixed(2) : 0
        let scontoP = prezzoListino && prezzo ? +((prezzoListino - prezzo) / prezzoListino * 100).toFixed(2) : 0
        p.prezzoCliente = prezzo
        p.infoPrezzo = {
          listino: condizioni.listino,
          prezzoListino: prezzoListino,
          prezzoCategoria: prezzoCategoriaSconti,
          scontoP: scontoP,
          sconto: sconto
        }
      }
    }
    prodotti.total_rows = ids.length || prodotti.total_rows
    return prodotti
  }
  catch (err) {
    console.log(err)
    return err;
  }
}

const prod_search_by_array = async(urlAuth, selector, elementi) => {
  try {
    const indexName = `${selector}_index`
    const options = {
      selector: {},
      fields: ['_id'],
      use_index: indexName
    }
    options.selector[selector] = {"$in" : elementi}

    const basicAuth = 'Basic ' + Buffer.from(`${urlAuth.username}:${urlAuth.password}`, 'utf-8').toString('base64')
    const response = await fetch(`${urlAuth.urlDB}/_find`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth
      },
      body: JSON.stringify(options)
    })
    const result = await response.json()

    return result
  }
  catch (err) {
    console.log('errore search', err)
    return err;
  }
}

const prod_giacenza_positiva = async(urlAuth, magazzinoGiacenza) => {
  try {
    let options = {
      selector: {},
      fields: ['_id']
    }
    if (magazzinoGiacenza) {
      options.selector[`magazzino.giacenzaParziale.${magazzinoGiacenza}.giacenza`] = { '$gt': 0 }
    } else {
      options.selector['magazzino.giacenza'] = { '$gt': 0 }
    }

    const basicAuth = 'Basic ' + Buffer.from(`${urlAuth.username}:${urlAuth.password}`, 'utf-8').toString('base64')
    const response = await fetch(`${urlAuth.urlDB}/_find`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth
      },
      body: JSON.stringify(options)
    })
    const result = await response.json()
    return result
  }
  catch (err) {
    console.log('errore search', err)
    return err;
  }
}

function creaCampoRicerca(pr) {
  const barcode = pr.barcode ? pr.barcode.map(el => el.codice) : [] // non filtro solo il barcode attivo, in modo d poter trovare i vari prodotti che hanno lo stesso barcode
  const stat = []
  if (pr.statistici) {
    for (var prop in pr.statistici) {
      if (Object.prototype.hasOwnProperty.call(pr.statistici, prop)) {
        stat.push(pr.statistici[prop].codice)
        stat.push(pr.statistici[prop].descrizione)
      }
    }
  }
  if (pr.varianti) {
    for (var prop in pr.varianti) {
      if (Object.prototype.hasOwnProperty.call(pr.varianti, prop)) {
        stat.push(pr.varianti[prop].codice)
        stat.push(pr.varianti[prop].descrizione)
      }
    }
  }

  // Si può semplificare la generazione (vedi clienti)
  // varianti = Object.values(.....)
  // _.without(varianti,undefined,null,'')
  
  const taglia =  pr.varianti &&  pr.varianti.variante1 &&  pr.varianti.variante1.codice || ''
  const colore =  pr.varianti &&  pr.varianti.variante2 &&  pr.varianti.variante2.codice || ''
  // const ricercaTC =  pr.codice + String(taglia) + String(colore)
  const ricercaTC = creaCampoRicercaTC_Codici(pr.codice, taglia, colore)
  return pouchCollate.toIndexableString([
    pr._id || '', // può essere diverso se aggiunge la sigla del marchio (es Metel)
    ricercaTC,
    pr.nome,
    pr.descrizione,
    pr.marchio,
    stat,
    barcode
  ])
}

function creaCampoRicercaTC(pr) {
  const taglia =  pr.varianti &&  pr.varianti.variante1 &&  pr.varianti.variante1.codice || ''
  const colore =  pr.varianti &&  pr.varianti.variante2 &&  pr.varianti.variante2.codice || ''
  // return pr.codice + String(taglia) + String(colore)

  var pattern = /[^a-zA-Z0-9-]+/gi
  // var campo = String(pr.codice) + String(taglia) + String(colore)
  var campo = `${String(pr.codice)}${taglia !== '-' ? String(taglia) : ''}${colore !== '-' ? String(colore) : ''}`
  return campo.replace(pattern, '_').toLowerCase();
}

function creaCampoRicercaTC_Codici(codice, taglia, colore) {
  var pattern = /[^a-zA-Z0-9-]+/gi
  var campo = String(codice) + String(taglia) + String(colore)
  return campo.replace(pattern, '_').toLowerCase();
}

function creaCampoRicercaBarcode(pr) {
  if (!pr.barcode) return ''
  const barcode =  pr.barcode.find(x => x.attivo) // TODO: considerare anche date di validita
  return barcode ? barcode.codice : ''
}

export default {
  getProdottiByIds,
  getProdotto,
  setProdotto,
  aggiornaPrezzo,
  getFilteredProducts,
  // getFilteredProducts2,
  prod_list_by_filter,
  prod_search_by_array,
  prod_giacenza_positiva
}