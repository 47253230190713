'use strict'
// TODO: Spostare su servizio generale in cloud
import moment from 'moment'
require('dotenv').config();
import PouchDB from 'pouchdb';
// PouchDB.plugin(require('pouchdb-find')); // npm install --save pouchdb-find
var scontrino_default = require('../modelli/scontrino.json');

const service = process.env.VUE_APP_service_licenze || ''

const elencoScontriniPerData = async (base, da, a, limit = 50) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'scontrini' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return

    let opz = { endkey: da, startkey: (a || '') + '\ufff0',  include_docs: true, descending: true } // estremi invertiti perchè descending ...
    if (limit > 0) {
      opz.limit = limit
    }
    const result = await db.allDocs(opz);
    await db.close()
    return result.rows.map(x => x.doc).filter(x => !x._id.startsWith('_'));
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

const creaScontrino = async function(base, dati, parcheggio) {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'scontrini' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    let scontrino = { ...scontrino_default, ...dati }
    scontrino.editInfo = {
      dataCreazione: new Date()
    }
    scontrino._id = moment().format() + Math.round(Math.random() * 1000)

    if (parcheggio) {
      scontrino.parcheggio = {
        stato: "occupato",
        zona: parcheggio.zona,
        posizione: parcheggio.posizione,
        data: new Date().toJSON(),
        payload: parcheggio.payload
      }
    }
    await db.put(scontrino)
    const creato = await db.get(scontrino._id)
    await db.close()
    return creato
  } catch (error) {
    return error
  }  
}

const addRigaScontrino = async (base, idScontrino, riga) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'scontrini' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    let scontrino = await db.get(idScontrino);
    scontrino.righe.push(riga);
    scontrino.editInfo.dataModifica = new Date();
    await db.put(scontrino);
    const salvato =  await db.get(scontrino._id)
    await db.close()
    return salvato
  } catch (error) {
    return error
  }    
}

const updateScontrino = async (base, idScontrino, righe) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'scontrini' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    let scontrino = await db.get(idScontrino);
    scontrino.righe = righe;
    scontrino.editInfo.dataModifica = new Date();
    await db.put(scontrino);
    const salvato =  await db.get(scontrino._id)
    await db.close()
    return salvato
  } catch (error) {
    return error
  }    
}

const getParcheggiati = async (base) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'scontrini' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    const data = {
      selector: {
        'parcheggio.stato': { "$in": ['occupato', 'aperto']}
      },
      use_index: 'park-index'
    }
    const basicAuth = 'Basic ' + Buffer.from(`${username}:${password}`, 'utf-8').toString('base64')
    const response = await fetch(`${urlDB}/_find`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth
      },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    await db.close()
    return result.docs    
  }
  catch (err) {
    console.log('errore getParcheggiati', err)
    return err;
  }
}

async function annullaParcheggio(base, idScontrino) {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'scontrini' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    let scontrino = await db.get(idScontrino);
    delete scontrino.parcheggio
    await db.put(scontrino);
    await db.close()
    return { status: 'deleted' }
  } catch (error) {
    return error
  } 
}

export default {
  elencoScontriniPerData,
  creaScontrino,
  addRigaScontrino,
  updateScontrino,
  getParcheggiati,
  annullaParcheggio
}
